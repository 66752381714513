<mat-sidenav #accountSidenav mode="side" class="sidenav sidenav-account">
  <div class="sidenav-account__actions" fxLayoutAlign="center center">
    <!--button mat-button [disableRipple]="true" (click)="headerService.toggleMyAccount();sidenav.close();">
      <mat-icon>arrow_back</mat-icon> Revenir
    </button-->
    <div fxFlex fxFlexAlign="flex-start">
      <button mat-button (click)="closeAllNav();" style="padding-left: 0">
        <!-- <mat-icon color="primary" class="icon--xl" aria-hidden="true" aria-label="menu">close</mat-icon> -->
        <mat-icon color="primary" class="icon--xl" aria-hidden="true" aria-label="menu">chevron_left</mat-icon>
      </button>
    </div>
    <div fxFlex="80" class="sidenav-account__actions-name">
      <!-- <ng-container *ngIf="authService.loginDisplay">
        <span>{{ authService.datas?.name }}</span>
        <span class="sidenav-account__actions-name__short">{{ authService.datas?.shortname }}</span>
      </ng-container> -->
    </div>


  </div>


  <!-- CANDIDATE -->
  <ng-template #isCandidate>
    <div class="sidenav-account__content">

      <ng-container *ngIf="!headerService.isLogged">
        <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
          <a class="sidenav-account__item-wrapper" title="connexion" [routerLink]="['mon-compte', 'identification']"
            (click)="closeAllNav()">

            <span class="sidenav-account__item-title">Se connecter</span>
          </a>
        </div>

        <div class="sidenav-account__item" fxLayout fxLayoutAlign="center center">
          <a class="sidenav-account__item-wrapper" title="inscription" [routerLink]="['inscription']"
            (click)="closeAllNav()">

            <span class="sidenav-account__item-title">S'inscrire</span>
          </a>
        </div>

      </ng-container>

      <!--div class="sidenav-account__item">
        <a class="sidenav-account__item-wrapper title--with-icon" title="mes recommandations">
          <div class="sidenav-account__item-icon title__icon">
            <img class="img" src="/assets/images/svg/bear.svg" alt="">
          </div>
          <span class="sidenav-account__item-title">Mes recommandations</span>
        </a>
      </div>

      <div class="sidenav-account__item">
        <a class="sidenav-account__item-wrapper title--with-icon" title="mes candidatures">
          <div class="sidenav-account__item-icon title__icon">
            <img class="img" src="/assets/images/svg/bear.svg" alt="">
          </div>
          <span class="sidenav-account__item-title">Mes Candidatures</span>
        </a>
      </div>

      <div class="sidenav-account__item">
        <a class="sidenav-account__item-wrapper title--with-icon" title="mes formations">
          <div class="sidenav-account__item-icon title__icon">
            <img class="img" src="/assets/images/svg/bear.svg" alt="">
          </div>
          <span class="sidenav-account__item-title">Mes formations</span>
        </a>
      </div>


      <div class="sidenav-account__item">
        <a class="sidenav-account__item-wrapper title--with-icon" title="mes alertes">
          <div class="sidenav-account__item-icon title__icon">
            <img class="img" src="/assets/images/svg/bear.svg" alt="">
          </div>
          <span class="sidenav-account__item-title">Mes alertes</span>
        </a>
      </div>

      <div class="sidenav-account__item">
        <a class="sidenav-account__item-wrapper title--with-icon" [routerLink]="['myaccount']" (click)="closeAllNav()"
          title="mon compte">
          <div class="sidenav-account__item-icon sidenav-account__item-icon--account title__icon">
            {{ shortName ? shortName : '' }}
          </div>
          <span class="sidenav-account__item-title">Mon Compte</span>
        </a>
      </div -->

    </div>
  </ng-template>
</mat-sidenav>