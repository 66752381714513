import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Injectable, } from '@angular/core';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  public userDataSub: Subject<any> = new Subject<any>();

  constructor(private cookie: CookieService, private http: HttpClient, private apollo: Apollo, httpLink: HttpLink, private auth: AuthService) {

  }

  public updateUserDatas(datas) {
    this.userDataSub.next(datas);
    this.auth.updateUserData(datas);
  }

  public userRegister = () => {
    return this.apollo.watchQuery({
      query: gql`query user(){
        user() {
         id,
         email,
         firstName,
         lastName,
         roles,
         userName
        }
      }`
    });
  }

  public sendEmailPasswordReminder = (email: string) => {
    return this.apollo.watchQuery({
      query: gql`query sendEmailPasswordReminder($email: String!){
        sendEmailPasswordReminder(email: $email) {
         id,
         errorMessage,
         status
        }
      }`, variables: { email: email }
    });
  }

}
