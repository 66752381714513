
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppBrowserModule } from './app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

const providers = [
  { provide: 'BASE_URL', deps: [], useValue: environment.path.root },
  { provide: 'AUTH_URL', useValue: environment.path.auth },
  { provide: 'API_URL', useValue: 'https://localhost:44340' },
  // { provide: 'windowObject', useFactory: windowFactory, deps: [] }
];

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
