import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { MyaccountComponent } from './pages/myaccount/myaccount.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'entreprises', pathMatch: 'full', loadChildren: () => import('./pages/company/landing/landing.module').then(m => m.CompanyLandingModule) },
  { path: 'entreprises/liste', pathMatch: 'full', loadChildren: () => import('./pages/company/landing/list/list.module').then(m => m.CompaniesListModule) },
  { path: 'entreprises/:id', loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
  { path: 'qui-sommes-nous', loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule) },
  {
    path: 'blog-candidats',
    loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'conditions-dutilisation',
    loadChildren: () => import('./pages/cgu/cgu.module').then(m => m.CguModule)
  },
  {
    path: 'politique-de-confidentialite',
    loadChildren: () => import('./pages/private-policy/private-policy.module').then(m => m.PrivatePolicyModule)
  },
  // {path: 'offres-emploi', loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersModule) },
  // { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
  // { path: 'myaccount', component: MyaccountComponent },
  { path: 'mon-compte', loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '404' },
]


@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', initialNavigation: 'enabled', scrollPositionRestoration: 'enabled' /* preloadingStrategy: PreloadAllModules */, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppRoutingModule { }
