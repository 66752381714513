<header class="header" ngClass.gt-sm="isDesktop"></header>

<sidenav *ngIf="isMobile" [hideSidenav]="closeSidenav">
</sidenav>

<div class="content" ngClass.gt-sm="isDesktop" (click)="dismissSidenav()">

  <router-outlet></router-outlet>
</div>

<footer class="footer" ngClass.gt-sm="isDesktop"></footer>

<ng-container *ngIf="!env.production">
  <b>ENV</b> : {{ env?.name | json}}, <b>ID</b> : {{ env?.GlobalVars.brand | json }}
</ng-container>

<div #cookiesBarre id="CookiesBarre" *ngIf="showCookiesBarre;" ngClass.gt-sm="isDesktop">
  <span>Ce site utilise des cookies pour vous offrir le meilleur service. En
    poursuivant votre
    navigation, vous acceptez l’utilisation des cookies.</span>
  <a class="text-color-white" href="/espace-recruteurs/declaration-de-confidentialite.aspx#EFAA" target="_blank"
    style="margin-left: 15px">En
    savoir plus</a>
  <a class="text-color-white" id="cookieChoiceDismiss" role="button" style="margin-left: 15px; cursor: pointer;"
    (click)="removeCookiesBarre($event)">J’accepte</a>
</div>