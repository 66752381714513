<mat-nav-list>
    <mat-list-item class="header-nav__link" fxFlex routerLinkActive="active" [disableRipple]="true">
        <a href="deposer-un-cv.aspx" title="Déposer un CV'">
            Déposer un CV
        </a>
    </mat-list-item>
    <mat-list-item class=" header-nav__link" fxFlex routerLinkActive="active" [disableRipple]="true">
        <a href="emploi.aspx" title="Trouver un emploi">
            Trouver un emploi</a>
    </mat-list-item>

    <mat-list-item class="header-nav__link" fxFlex routerLinkActive="active" [disableRipple]="true">
        <a [routerLink]="['entreprises']" title="Entreprises">
            portraits d'entreprises
        </a>
    </mat-list-item>

    <mat-list-item class="header-nav__link" fxFlex routerLinkActive="active" [disableRipple]="true">
        <a [routerLink]="['blog-candidats']" title="Blog Candidats">
            blog candidats
        </a>
    </mat-list-item>
</mat-nav-list>