import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ChangeDetectorRef, Input, ViewEncapsulation } from '@angular/core';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Subscription, Observable } from 'rxjs';
import { environment } from '@env';


@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {
  env = environment
  loading: boolean = true;
  @Input('show') showLoading: boolean;
  @Input() static: boolean;
  @Input() absolute: boolean;
  @Input() verticalPosition;
  @Input() allScreen: boolean;
  @Input() defaultAnimation: boolean;

  constructor(private loaderService: LoadingService) {

  }

  ngOnInit() {
    if (this.showLoading === undefined) {
      this.loaderService.isLoading.subscribe((v) => {
        this.loading = v;
      });
    } else { // Force displaying
      this.loading = this.showLoading;
    }
  }

  ngAfterViewChecked() {
  }

}

