import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderService } from 'src/app/core/services/header.service';
import { HeaderComponent } from '../header/header.component';
import { SidenavAccountComponent } from './sidenav-account/sidenav-account.component';
import { Subscription, Subject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env';
// import { AuthMSALService } from 'src/app/core/services/auth-msal.service';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {
  env = environment;
  private userDataSubscription: Subscription = new Subscription();
  public rootPath: string = '';

  @ViewChild('mainSidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild(SidenavAccountComponent, { static: true }) public sidenavAccount: MatSidenav;

  username: any;
  shortUsername: string;
  email: string;
  routerSub: Subscription = new Subscription();

  // popinConf = new MatDialogConfig();

  constructor(public router: Router, public popin: MatDialog, public headerService: HeaderService, /*public authService: AuthMSALService*/) {
  }


  ngOnInit() {
    if (!this.headerService.showAccountMenu) {
      this.headerService.setSidenav(this.sidenav);
    }


    /* this.userDataSubscription = this.authService.getUserData().subscribe(userData => {

      if (userData) {
        this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
        userData.role.indexOf('RECRUTEUR') !== -1 ? this.authService.roles.recruiter = true : this.authService.roles.recruiter = false;
        userData.role.indexOf('RECRUTEUR-COORDINATEUR') !== -1 ? this.authService.roles.coordinator = true : this.authService.roles.coordinator = false;

        userData.role.indexOf('CANDIDAT') !== -1 ? sessionStorage.setItem('returnurl', '/candidats/mes-offres-recommandees.aspx') : '';
      }

      // this.isRecruiters ? this.myaccountPath = '/espace-recruteurs/mon-compte/ma-societe.aspx' : this.myaccountPath = '/candidats/mon-compte.aspx';
    }); */

  }

  isOpen = false;
  openedChange(event) {
    this.isOpen = event;
    this.headerService.sidenavOpenedChange$.next(this.isOpen);
  }

  logout() {
    // this.authService.logout();
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
    // this.userDataSubscription.unsubscribe();
    this.headerService.close();
  }

  @Input('hideSidenav') hideSidenav: Subject<boolean>;
  ngAfterContentInit() {
    this.hideSidenav.subscribe(
      status => {
        if (status) {
          this.headerService.close();
        }
      }
    );
  }

  /* openLoginDialog() {
    this.popinConf = {
      id: 'popin',
      width: '80vw',
      maxWidth: '500px',
      maxHeight: 'auto',
      data: {

        email: this.email
      }
    };

    const popinRef = this.popin.open(PopinLoginComponent, this.popinConf);
  } */

  public toggleMyAccount() {
    this.sidenav.close();
    this.headerService.showAccountMenu = !this.headerService.showAccountMenu;
    this.sidenavAccount.open();
  }

}
