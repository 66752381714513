import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
// import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserService } from 'src/app/core/services/user.service';
// import { PopinJobDepositComponent } from '../../popin/popin-jobDeposit/popin-jobDeposit.component';

@Component({
  selector: 'sidenav-account',
  templateUrl: './sidenav-account.component.html',
  styleUrls: ['./sidenav-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavAccountComponent implements OnInit {
  @ViewChild('accountSidenav', { static: true }) public sidenav: MatSidenav;
  @Input() shortName: string;
  @Input() isRecruiters: string;
  shortUsername: string;
  userDataSubscription: Subscription;
  username: string;

  constructor(public headerService: HeaderService, /*public authService: AuthMSALService,*/
    private popin: MatDialog, private userService: UserService) {
  }

  ngOnInit() {

    this.headerService.accountMenu.subscribe(show => {
      if (show) {
        return this.sidenav.open();
      }
    });

  }

  ngAfterViewInit() {
    /*  setTimeout(() => {
       this.userDataSubscription = this.authService.getUserData().subscribe(userData => {
         if (userData) {
           this.username = `${userData.given_name} ${userData.family_name}`
           this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
           this.authService.handleRoles(userData);
         }
       });
 
       // If name is updated (my account)
       this.userService.userDataSub.subscribe(userData => {
         this.username = `${userData.given_name} ${userData.family_name}`
         this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
       });
     }); */
  }

  open() {
    return this.sidenav.open();
  }

  closeAllNav() {
    this.sidenav.close();
    //this.headerService.toggleMyAccount();
  }

}
