<ng-container *ngIf="!showDiv">
  <loading [static]="true"></loading>
</ng-container>


<ng-container *ngIf="showDiv">
  <div style="min-height: 70vh" fxLayout fxLayoutAlign="center center">
    <p fxFlex class="text--center">
      <strong style="font-size: 20px">Vous avez été déconnecté.</strong>
    </p>
  </div>
</ng-container>