import { Injectable } from '@angular/core';
import { OidcSecurityStorage, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class CustomStorage implements OidcSecurityStorage {

  private openIdConfig: OpenIdConfiguration

  constructor(private cookieService: CookieService) {
  }

  public read(key: string): any {
    let val = this.cookieService.get(key + '_' + 'angular');

    // console.log('read', key, val);

    if (val && val !== '') {
      return JSON.parse(val);
    }

    return
  }

  public write(key: string, value: any): void {
    let path = '/'
    /*if ((<any>window).location.pathname.includes('espace-recruteurs')) {
      path = '/espace-recruteurs';
    }*/

    // console.log(key, key === 'authStateControl', path);

    value = value === undefined ? null : value;

    this.cookieService.delete(key + '_' + 'angular');

    this.cookieService.set(
      key + '_' + 'angular',
      JSON.stringify(value), 10, path);

    // console.log('STORAAAAAAAAGE', key, value)


    return
  }

}