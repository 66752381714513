<div class="loading-screen-wrapper"
  [ngClass]="{'loading-screen-wrapper--static' : static, 'loading-screen-wrapper--absolute': absolute, 'loading-screen-wrapper--allScreen': allScreen}">
  <div class="loading-screen-icon" [ngStyle]="{'top' : verticalPosition}">
    <ng-container *ngIf="defaultAnimation || defaultAnimation === undefined">
      <div class="blobs">
        <div class="blob-center"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
      </div>
      <div class="loading-screen-text">En cours de chargement...</div>
    </ng-container>


    <ng-container *ngIf="defaultAnimation === false">
      <img [src]="env.path.img + '/icons/icon512x512_half.png'" alt="">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>

      <div class="loading-screen-text">En cours de chargement...</div>
    </ng-container>

  </div>
</div>