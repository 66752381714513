<!-- MOBILE -->
<ng-container *ngIf="isMobile && displayFooter">
  <mat-divider class="divider"></mat-divider>
  <mat-expansion-panel class="footer-block mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="footer-block__title">
        Services
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="footer-list">
      <li class="footer-list__item" *ngFor="let service of environment.DK.footer.services">
        <a href="{{service.url}}">
          {{ service.title }}
        </a>
      </li>

    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel class="footer-block mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="footer-block__title">
        à propos de nous
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ul class="footer-list">
      <li class="footer-list__item">
        <a [routerLink]="['politique-de-confidentialite']">
          Charte de confidentialité
        </a>
      </li>
      <li class="footer-list__item">
        <a [routerLink]="['conditions-dutilisation']">
          Conditions d'utilisation
        </a>
      </li>
      <li class="footer-list__item">
        <a href="espace-recruteurs/conditions-generales-de-vente.aspx">
          Conditions générales de vente
        </a>
      </li>
      <li class="footer-list__item">
        <a [routerLink]="['qui-sommes-nous']">
          Qui sommes-nous ?
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel class="footer-block mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="footer-block__title">
        communauté/ support
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="footer-list">
      <li class="footer-list__item">
        <a href="{{supportUrl}}">
          Centre d'aide
        </a>
      </li>
      <li class="footer-list__item" *ngFor="let social of environment.DK.footer.support">
        <a href="{{social.url}}" target="_blank">
          {{social.title}}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel class="footer-block mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="footer-block__title">
        CV par poste
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="footer-list">
      <li class="footer-list__item" *ngFor="let letter of alphabets" style="display: inline-block; margin-right: 13px">
        <a [attr.href]="cvPath + '/list/spos/l/'+letter+'.aspx'" target="_blank">
          {{letter}}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <mat-expansion-panel class="footer-block mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title class="footer-block__title">
        CV par région
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ul class="footer-list">
      <li class="footer-list__item" *ngFor="let city of cities;" style="display: inline-block; margin-right: 10px">
        <a target="_blank" [attr.href]="cvPath + '/list/spos/reg/'+city.id+'.aspx'">
          {{city.name}}
        </a>
      </li>
    </ul>
  </mat-expansion-panel>

  <div class="footer-security">
    <h4>Sécurité des données</h4>
    {{ environment.GlobalVars.name | titlecase}} s'engage à protéger toutes vos données personnelles conformément à la
    loi
    informatique et libertés
    du 6 janvier 1978.<br />
    Pour en savoir plus, consultez la <a href="espace-recruteurs/declaration-de-confidentialite.aspx"
      target="_blank">charte de
      confidentialité.</a>
  </div>
</ng-container>


<!-- DESKTOP -->
<ng-container *ngIf="!isMobile && displayFooter">
  <div class="container" fxHide [fxShow]="!isMobile">
    <div class="footer-section content__section" fxLayout>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          services
        </div>
        <ul class="footer-list">
          <li class="footer-list__item" *ngFor="let service of environment.DK.footer.services">
            <a href="{{service.url}}">
              {{ service.title }}
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          à propos de nous
        </div>

        <ul class="footer-list">
          <li class="footer-list__item">
            <a [routerLink]="['politique-de-confidentialite']">
              Charte de confidentialité
            </a>
          </li>
          <li class="footer-list__item">
            <a [routerLink]="['conditions-dutilisation']">
              Conditions d'utilisation
            </a>
          </li>
          <li class="footer-list__item">
            <a href="espace-recruteurs/conditions-generales-de-vente.aspx">
              Conditions générales de vente
            </a>
          </li>
          <li class="footer-list__item">
            <a [routerLink]="['qui-sommes-nous']">
              Qui sommes-nous ?
            </a>
          </li>
          <li class="footer-list__item">
            <a href="m.aspx">
              Site mobile
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          communauté / support
        </div>
        <ul class="footer-list">
          <li class="footer-list__item">
            <a href="{{supportUrl}}">
              Centre d'aide
            </a>
          </li>
          <li class="footer-list__item" *ngFor="let support of environment.DK.footer.support">
            <a href="{{support.url}}" target="_blank">
              {{ support.title}}
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          Sécurité des données
        </div>
        {{ environment.GlobalVars.name | titlecase }} s'engage à protéger toutes vos données personnelles conformément à
        la loi
        informatique et libertés
        du 6 janvier 1978.<br />
        Pour en savoir plus, consultez la <a class="text-color-white"
          href="espace-recruteurs/declaration-de-confidentialite.aspx" target="_blank">charte de
          confidentialité.</a>
      </div>
    </div>

    <div class=" footer-section content__section" fxLayout>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          {{cvTitle}} par poste
        </div>
        <ul class="footer-list" fxFlex="75%" fxFlexOffset="20px">
          <li class="footer-list__item" *ngFor="let letter of alphabets" fxFlex>
            <a target="_blank" [attr.href]="cvPath + '/list/spos/l/'+letter+'.aspx'">
              {{letter}}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-section content__section" fxLayout>
      <div class="footer-section__item" fxFlex>
        <div class="footer-title">
          {{cvTitle}} par région
        </div>

        <ul class="footer-list" fxFlexOffset="20px" fxLayout="column">
          <ng-container *ngFor="let city of cities; index as i">
            <li *ngIf="i<5" class="footer-list__item" fxFlex="25%">
              <a target="_blank" [attr.href]="cvPath + '/list/spos/reg/'+city.id+'.aspx'">
                {{city.name}}
              </a>
            </li>
          </ng-container>
        </ul>

        <ul class="footer-list" fxFlexOffset="20px" fxLayout="column">
          <ng-container *ngFor="let city of cities; index as i">
            <li *ngIf="i>=5 && i<10" class="footer-list__item" fxFlex="25%">
              <a target="_blank" [attr.href]="cvPath + '/list/spos/reg/'+city.id+'.aspx'">
                {{city.name}}
              </a>
            </li>
          </ng-container>
        </ul>

        <ul class="footer-list" fxFlexOffset="20px" fxLayout="column">
          <ng-container *ngFor="let city of cities; index as i">
            <li *ngIf="i>=10 && i<15" class="footer-list__item" fxFlex="25%">
              <a target="_blank" [attr.href]="cvPath + '/list/spos/reg/'+city.id+'.aspx'">
                {{city.name}}
              </a>
            </li>
          </ng-container>
        </ul>

        <ul class="footer-list" fxFlexOffset="20px" fxLayout="column">
          <ng-container *ngFor="let city of cities; index as i">
            <li *ngIf="i>=15" class="footer-list__item" fxFlex="25%">
              <a target="_blank" [attr.href]="cvPath + '/list/spos/reg/'+city.id+'.aspx'">
                {{city.name}}
              </a>
            </li>
          </ng-container>
        </ul>

      </div>
    </div>

  </div>
</ng-container>

<div class="footer-copyright text--center">
  <ng-container *ngIf="!isRecruiters">
    {{ environment.DK.copyright }}-{{ year }}
    {{ environment.GlobalVars.name }}.
  </ng-container>

  <ng-container *ngIf="isRecruiters">
    {{ environment.DK.copyright }}-{{ year }} Jobology.
  </ng-container>

</div>