import { Component, OnInit } from '@angular/core';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor(private auth: AuthMSALService) { }

  ngOnInit() {
    setTimeout(() => {
      this.auth.logout();
    }, 4500)
  }

}
