import { Component, OnInit, Input, Output, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/services/header.service';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
// import { AuthMSALService } from 'src/app/core/services/auth-msal.service';

@Component({
  selector: 'nav-right',
  templateUrl: './nav-right.component.html',
  // styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent implements OnInit {
  @Input('isSticky') isSticky: any;
  @Input('isLogged') isLogged: boolean;

  // @Output() switchTheme = new EventEmitter();
  public env = environment;
  public shortUsername: string;
  private userDataSubscription: Subscription = new Subscription();

  constructor(public popin: MatDialog, private router: Router, public headerService: HeaderService,
    /*public authService: AuthMSALService,*/ private userService: UserService, public render: Renderer2,
    public el: ElementRef) {

  }

  ngOnInit() {
    /*  this.userDataSubscription = this.authService.getUserData().subscribe(userData => {
      if (userData) {
        this.name = `${userData.given_name} ${userData.family_name}`
        this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
 
        this.authService.handleRoles(userData);
        userData.role.indexOf('CANDIDAT') !== -1 ? sessionStorage.setItem('returnurl', '/candidats/mes-offres-recommandees.aspx') : '';
      }
    });

   // If name is updated (my account)
   this.userService.userDataSub.subscribe(userData => {
     this.name = `${userData.given_name} ${userData.family_name}`
     this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
   }); */

  }

  ngAfterContentChecked(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    this.userDataSubscription.unsubscribe();

  }

  public login() {
    //  this.authService.login();
  }

  public logout() {
    /* setTimeout(() => {
      this.authService.logout();
    }, 200); */
    // this.authService.logout();
  }

}
