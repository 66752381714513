<mat-toolbar class="header__wrapper" #header (scroll)="onScroll($event)" [fxHide]="pwaIsActive">
  <div class="logo" fxLayout fxLayoutAlign="center center" fxFlex.lt-sm="60%" ngClass.gt-sm="desktop">

    <div class="logo__wrapper">
      <a [href]="env.path.root" class="logo__link" [title]="env.GlobalVars.name"
        (click)="isMobile ? headerService.close() : $event.stopPropagation()">
        <!--svg class="logo__img" fxFlexOffset="15px" fxFlex="75%" fxFlexAlign="center">
          <use [attr.xlink:href]="logoImg"></use>
        </svg-->
        <img class="logo__img" fxFlex="75%" fxFlexAlign="center" [attr.alt]="'logo ' + env.GlobalVars.name"
          [src]="env.path.img + '/svg/logo.svg#logo?v=091023'">

      </a>
    </div>
  </div>

  <!-- MOBILE HEADER -->
  <ng-container *ngIf="isMobile; else headerDesktop">
    <mat-sidenav-container ngClass="header-nav" fxLayout fxLayoutAlign="flex-end center">

      <mat-sidenav-content fxLayout fxLayoutAlign="center center" fxLayout ngClass="header-nav__wrapper">

        <button ngClass="header-nav__link header-nav__link--cv" mat-button [disableRipple]="true">
          <!-- a title="Rechercher" (click)="toggleSearch()" -->
          <a title="Rechercher" href="emploi.aspx">
            <mat-icon color="primary" class="nav-icon" svgIcon="search" aria-hidden="true" aria-label="rechercher">
            </mat-icon>
          </a>
        </button>



        <!-- BURGER NAV CANDIDATES -->
        <button [class.active]="headerService.toggleActive" ngClass="header-nav__link header-nav__link--burger-menu"
          mat-button [disableRipple]="true" (click)="toggle()" aria-label="navigation menu" *ngIf="isMobile">
          <ng-container *ngIf="!headerService.toggleActive; else navOpen">
            <mat-icon color="primary" class="nav-icon" aria-hidden="true" aria-label="open menu">menu
            </mat-icon>
          </ng-container>

          <ng-template #navOpen>
            <mat-icon color="primary" class="nav-icon" aria-hidden="true" aria-label="close menu">close
            </mat-icon>
          </ng-template>

        </button>
        <!-- 
        <button class="header-nav__link" mat-button [disableRipple]="true" aria-label="login menu"
          *ngIf="authService.loginDisplay">
          <span class="header-nav__link-account"
            (click)="headerService.toggleMyAccount()">{{authService.datas?.shortname}}</span>
        </button> -->

      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>

  <!-- desktop header-->
  <ng-template #headerDesktop>
    <div fxLayout fxHide.xs fxShow.gt-sm class="header-nav header-nav--desktop">
      <!-- CANDIDAT -->
      <ng-container>
        <nav-candidate></nav-candidate>
      </ng-container>

      <div style="display: flex; flex: 1 1 auto;"></div>

      <!--======= RIGHT NAV ========-->
      <nav-right [isSticky]="isSticky">
      </nav-right>

    </div>
  </ng-template>

</mat-toolbar>


<ng-container *ngIf="showSearch">
  <searchblock [headerSearch]="headerSearch" fxHide.gt-sm="true"></searchblock>
</ng-container>