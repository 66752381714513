<div class="container">
  <div class="content__section">

    <div fxLayout fxLayoutAlign="center">
      <img alt="" [src]="logoColor">
    </div>

    <h3 class="text--center" style="font-size: 30px; color: #454545; margin-top: 30px">
      La page que vous demandez est introuvable.
    </h3>

    <p class="text--center" style="padding: 30px 15px" fxLayout fxLayoutAlign="space-around">

      <a [routerLink]="['']" fxFlex>
        Retourner à la page d'accueil
      </a>
    </p>
  </div>
</div>