import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})

export class MetaService {
  constructor(@Inject(DOCUMENT) private dom, private meta: Meta, private title: Title) { }

  createCanonicalURL(url?: string, absoluteUrl: boolean = false) {
    let canURL = url == undefined ? `${environment.path.root}${this.dom.URL}` : `${environment.path.root}${url}`;

    if (absoluteUrl) {
      canURL = url;
    }

    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  deleteCanonicalURL() {
    const dom = this.dom.getElementsByTagName('link');

    for (let i = 0; i < dom.length; i++) {
      if (dom[i].rel === 'canonical') {
        dom[i].remove();
      }
    }
  }

  createLinkTag(url: string, attrName: string) {
    let linkURL = url == undefined ? `${environment.path.root}${this.dom.URL}` : `${environment.path.root}${url}`;
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', attrName);
    this.dom.head.appendChild(link);
    link.setAttribute('href', linkURL);
  }

  deleteLinkTag(attrName: string) {
    const dom = this.dom.getElementsByTagName('link');

    for (let i = 0; i < dom.length; i++) {
      if (dom[i].rel === attrName) {
        dom[i].remove();
      }
    }
  }

  setTitleDescription(title?, description?) {
    // Capitalize first letter
    let siteName = environment.GlobalVars.name.charAt(0).toUpperCase() + environment.GlobalVars.name.slice(1);

    if (environment.GlobalVars.name === 'supply-chain') {
      siteName = siteName + '.fr';
    }

    let formatTitle = title ? title : environment.DK.metaTitle;
    let formatDescription = description ? description : environment.DK.metaDescription;

    this.title.setTitle(`${formatTitle} | ${siteName}`);
    this.meta.updateTag({ name: 'description', content: formatDescription });
  }

  envName = environment.GlobalVars.name[0].toUpperCase() + environment.GlobalVars.name.substr(1, environment.GlobalVars.name.length);
  setDefaultOG(title?, description?) {
    const metaImg = `${environment.path.prod}/Portals/_default/Skins/BASE_${environment.GlobalVars.brand}/images/avatar_fb.png`;

    const formatTitle = title ? title : environment.DK.metaTitle;
    const formatDescription = description ? description : environment.DK.metaDescription;

    this.meta.addTags([
      //{ property: 'og:url', content: `${environment.path.root}${this.router.url}` },
      { property: 'og:title', content: `${formatTitle} | ${this.envName}` },
      { property: 'og:description', content: formatDescription },
      { property: 'og:image', content: metaImg },
      { property: 'og:image:url', content: metaImg },
      { name: 'twitter:image', content: metaImg },
      { name: 'twitter:card', content: "summary" },
    ]);
  }

  deleteOG() {
    this.meta.removeTag("property='og:url'");
    this.meta.removeTag("property='og:title'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:image'");
    this.meta.removeTag("property='og:image:url'");
    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("name='twitter:image'");
  }
}