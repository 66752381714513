import { Component, OnInit, ViewEncapsulation, HostListener, ElementRef, ViewChild, Input, Renderer2, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { HeaderService } from '../../core/services/header.service';
import { IconsService } from 'src/app/core/services/icons.service';
import { Subject, Subscription } from 'rxjs';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '@env';
import { Router, NavigationEnd } from '@angular/router';
import { RouterNavService } from 'src/app/core/services/router-nav.service';

import { SidenavAccountComponent } from '../sidenav/sidenav-account/sidenav-account.component';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'src/app/core/services/user.service';
import { isPlatformBrowser } from '@angular/common';
//import { AuthMSALService } from 'src/app/core/services/auth-msal.service';


@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  /* animations: [
    trigger('slideDown', [
      state('hide', style({

      }))
    ])
  ] */
})
export class HeaderComponent implements OnInit {
  headerSearch: boolean = true;
  showSearch: boolean = false;

  public env = environment;
  public isMobile: boolean = true;
  isDesktop;
  // public shortUsername: string;
  public logoImg: string;
  public rootPath: string;
  private routerSub: Subscription;
  isSticky = {
    mode: false,
    threshold: false
  };

  userDataSubscription: Subscription;

  @Input('showSecondTheme') toto: boolean;
  // @Output() switchTheme = new EventEmitter();

  private readonly _destroying$ = new Subject<void>();

  basketCountIsVisible;
  constructor(private breakpointObserver: BreakpointObserver, private renderer: Renderer2, public router: Router, private routerNav: RouterNavService,
    public breakpoints: BreakpointService, public headerService: HeaderService, private icons: IconsService,
    /*public authService: AuthMSALService,*/ private userService: UserService, @Inject(PLATFORM_ID) private platformId: any) {

    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait])
      .subscribe((state) => {
        this.isMobile = state.matches;
      });
  }

  @ViewChild('header', { read: ElementRef, static: true }) public headerBlock: ElementRef;
  @ViewChild('banner', { read: ElementRef, static: false }) public bannerBlock: ElementRef;
  // Scrolling manager for HEADER
  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    /*     let headerElem = this.headerBlock.nativeElement;
    
        this.displayHeader(headerElem.parentElement);
    
        // If banner is displayed
        if (this.bannerBlock !== undefined) {
          headerElem = this.bannerBlock.nativeElement;
        }
    
        if (this.window.nativeWindow.pageYOffset > 0) {
          this.renderer.addClass(headerElem, 'mat-elevation-z4')
          this.renderer.addClass(headerElem.parentElement, 'header--sticky');
          this.isSticky.mode = true;
    
          if (this.window.nativeWindow.pageYOffset > 1400) {
            this.renderer.addClass(headerElem.parentElement, 'header--sticky-threshold');
            this.isSticky.threshold = true;
          } else {
            this.renderer.removeClass(headerElem.parentElement, 'header--sticky-threshold');
            this.isSticky.threshold = false;
          }
        }
        else {
          this.renderer.removeClass(headerElem, 'mat-elevation-z4');
          this.renderer.removeClass(headerElem.parentElement, 'header--sticky');
          this.renderer.removeClass(headerElem.parentElement, 'header--sticky-threshold');
          this.isSticky.mode = false;
          this.isSticky.threshold = false;
        } */
  }

  private prevScrollpos: number = 0;
  displayHeader(headerElement) {
    if (!this.isMobile) {
      this.renderer.removeClass(headerElement, 'scroll-top');
      this.renderer.removeClass(headerElement, 'scroll-down');

      return;
    }

    const currentScrollPos = (<any>window).pageYOffset;

    if (this.prevScrollpos < currentScrollPos) {
      this.renderer.addClass(headerElement, 'scroll-down');
    } else {
      this.renderer.removeClass(headerElement, 'scroll-down');
    }
    this.prevScrollpos = currentScrollPos;

    // Hide btn on top
    setTimeout(() => {
      if ((<any>window).pageYOffset === 0) {
        this.renderer.removeClass(headerElement, 'scroll-down');
      }
    }, 10);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  toggle() {
    //  let headerElem = this.headerBlock.nativeElement;

    this.headerService.toggle();

    if (this.headerService.toggleActive) {
      //this.renderer.removeClass(headerElem, 'mat-elevation-z4')
      this.renderer.addClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen')
    } else {
      //this.renderer.addClass(headerElem, 'mat-elevation-z4')
      this.renderer.removeClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen')
    }
  }


  @ViewChild(SidenavAccountComponent, { static: false }) public sidenavAccount: MatSidenav;
  public toggleMyAccount() {
    /*this.headerService.close();
    this.headerService.showAccountMenu = !this.headerService.showAccountMenu;
    this.sidenavAccount.open();*/
  }

  public getLogo(): string {
    //this.isRecruiters ? this.logoImg = `${environment.path.img}/svg/logoR.svg#logo` : this.logoImg = `${environment.path.img}/svg/logo.svg#logo`;
    this.logoImg = `${environment.path.img}/svg/logo.svg#logo`;

    return this.logoImg;
  }

  bannerIsActive: boolean = false;
  shortUsername: string;
  sidenavIsOpen;
  pwaIsActive = false;
  ngOnInit() {
    // DETECT PWA MODE
    if (isPlatformBrowser(this.platformId)) {
      this.pwaIsActive = (<any>window).matchMedia('(display-mode: standalone)').matches;
    }


    // Listen sidenav opening
    this.headerService.sidenavOpenedChange$.subscribe(result => {
      if (!result) {
        this.renderer.removeClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen');
      }
    })


    // this.getLogo();


    /*   this.userDataSubscription = this.authService.getUserData().subscribe(userData => {
        if (userData) {
          this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
        }
      });
   */

    // If name is updated (my account)
    /*   this.userService.userDataSub.subscribe(userData => {
        this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
      });
   */

  }

  ngAfterViewInit() {

  }

  ngAfterContentChecked() {

  }

  ngOnDestroy() {
    // this.routerSub.unsubscribe();
  }
}
