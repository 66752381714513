import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { LoadingService } from 'src/app/core/services/loading.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from 'src/app/core/interceptors/loading.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingModule {
  static forRoot(): ModuleWithProviders<LoadingModule> {
    return {
      ngModule: LoadingModule,
      providers: [
        LoadingService,
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
      ]
    }
  }
}
