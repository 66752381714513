import { Component, OnInit } from '@angular/core';
// import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeaderService } from 'src/app/core/services/header.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  //setUrlParam: string = this.actRoute.snapshot.queryParamMap.get('set');
  // returnUrlParam: string = this.actRoute.snapshot.queryParamMap.get('returnurl');
  usernameParam: string = this.actRoute.snapshot.queryParamMap.get('username');
  setUrlParam = decodeURIComponent((<any>window).location.search.split('&set=')[1]);
  returnUrlParam;

  showDiv = false;

  constructor(private actRoute: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService, private cookie: CookieService,
    private router: Router) { }


  ngOnInit() {
    /* this.returnUrlHandle();

   if (this.returnUrlParam) {
     sessionStorage.setItem('returnurlparam', this.returnUrlParam);

     setTimeout(() => {

       const param = `set=${this.setUrlParam}`;
       const url: string = `/espace-recruteurs/authenticate/login?${param}`;

       sessionStorage.setItem('set', url);
       this.oidcSecurityService.logoff();
     }, 600);
   } else {
     this.authService.logout();
   }
*/

    /* if (environment.name !== 'dev' && this.cookie.get('referer') && this.cookie.get('referer') !== 'http://localhost:4000/') {
      (<any>window).location.href = `${this.cookie.get('referer')}espace-recruteurs/accueil/ctl/logoff.aspx`
    } else {
      this.showDiv = true;
    } */

    (<any>sessionStorage).removeItem('initUrl');

    if (environment.name !== 'dev' && (<any>sessionStorage).getItem('sourceUrl') !== null) {
      this.formatSourceUrl();
      (<any>window).location.href = `${this.sourceUrl}espace-recruteurs/accueil/ctl/logoff.aspx`
    } else {
      this.showDiv = true;
    }
  }

  returnUrlHandle() {
    (<any>window).location.search ? this.returnUrlParam = decodeURIComponent((<any>window).location.search.split('returnurl=')[1])
      : this.returnUrlParam = undefined;

    if (this.returnUrlParam !== undefined) {
      this.returnUrlParam = decodeURIComponent(this.returnUrlParam.split('&set=')[0]);
    }

    return this.returnUrlParam;

  }

  sourceUrl;
  formatSourceUrl() {
    this.sourceUrl = `https://${(<any>sessionStorage).getItem('sourceUrl')}/`;
    let url = (<any>sessionStorage).getItem('sourceUrl');

    // Rewrite URL for PREPROD
    if (!environment.production) {
      this.sourceUrl = url.includes('www') ? `https://preprod${url.split('www')[1]}/` : this.sourceUrl;
    }

    return this.sourceUrl;
  }

}
