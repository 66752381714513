<mat-sidenav #mainSidenav mode="side" class="sidenav mat-elevation-z4" (openedChange)="openedChange($event)">
  <ul class="sidenav__list">

    <!-- CANDIDATES -->
    <li class=" sidenav__list-item" routerLinkActive="active">
      <a class="sidenav__list-item__link" href="deposer-un-cv.aspx" (click)="headerService.toggle()">
        Déposer son CV</a>
    </li>

    <li class=" sidenav__list-item" routerLinkActive="active">
      <a class="sidenav__list-item__link" href="emploi.aspx" (click)="headerService.toggle()">
        Trouver un emploi</a>
    </li>

    <li class=" sidenav__list-item" routerLinkActive="active">
      <a class="sidenav__list-item__link" [routerLink]="['entreprises']" (click)="headerService.toggle()">
        Portraits d'entreprises</a>
    </li>

    <li class=" sidenav__list-item" routerLinkActive="active">
      <a class="sidenav__list-item__link" [routerLink]="['blog-candidats']" (click)="headerService.toggle()">
        Blog Candidats</a>
    </li>

    <!-- <li class="sidenav__list-item" *ngIf="!authService.loginDisplay">
      <a class="sidenav__list-item__link" href="candidats/identification-candidat.aspx"
        (click)="headerService.toggle()">Se connecter</a>
    </li>

    <li class="sidenav__list-item" *ngIf="authService.loginDisplay">
      <a class="sidenav__list-item__link" href="candidats/ctl/logoff.aspx" (click)="headerService.toggle()">Se
        déconnecter</a>
    </li>


    <li class="sidenav__list-item" *ngIf="!authService.loginDisplay">
      <a class="sidenav__list-item__link" href="espace-recruteurs/" (click)="headerService.toggle()">Espace
        Recruteurs</a>
    </li> -->



    <!--li class="sidenav__list-item" [fxHide]="headerService.isLogged">
      <!--a class="sidenav__list-item__link" (click)="switchedTheme(); headerService.toggle()"-->
    <!--a class="sidenav__list-item__link" [href]="isRecruiters ? '/' : '/espace-recruteurs'"
        [title]="isRecruiters ? 'Espace Candidat' : 'Espace Recruteurs'">
        {{isRecruiters ? 'Espace Candidat' : 'Espace Recruteurs'}}
      </!--a>
    </li-->
  </ul>
</mat-sidenav>

<!--sidenav-account [shortName]="shortUsername">
</sidenav-account-->