import { Injectable, OnDestroy, Inject, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from '../../auth.config.ts';
import { filter, takeUntil } from 'rxjs/operators';
import { HeaderService } from './header.service';

@Injectable({ providedIn: 'root' })
export class AuthMSALService {
  isIframe = false;
  loginDisplay = false;
  datas;
  userDatas: Subject<any> = new Subject;
  roles = {
    recruiter: false,
    coordinator: false,
    consultantJobology: false
  };

  private readonly _destroying$ = new Subject<void>();


  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private headerService: HeaderService) {
    // this.isIframe = window !== window.parent && !window.opener;
  }


  setLoginDisplay(datas?) {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  broadcastInProgress(subject) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(subject)
      )
      .subscribe(() => {
        this.datas = this.authService.instance.getActiveAccount()?.idTokenClaims;
        this.setLoginDisplay(this.datas);

        if (this.datas?.name) {
          this.datas.shortname = this.headerService.getShortname(this.datas?.family_name, this.datas?.given_name);
        }

        if (this.datas?.roles) {
          this.handleRoles(this.datas.roles)
        }
        /* 
                if (!this.datas?.userId) {
                  this.loginDisplay = false;
        
                } */

        this.userDatas.next(this.datas);
      });
  }

  broadcastMSAL(subject) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(subject)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

  }

  handleRoles(userRoles) {
    userRoles.indexOf('RECRUTEUR') !== -1 ? this.roles.recruiter = true : this.roles.recruiter = false;
    userRoles.indexOf('RECRUTEUR-COORDINATEUR') !== -1 ? this.roles = { recruiter: false, coordinator: true, consultantJobology: false } : this.roles.coordinator = false;
    userRoles.indexOf('CONSULTANT-JOBOLOGY') !== -1 ? this.roles = { recruiter: false, coordinator: false, consultantJobology: true } : this.roles.consultantJobology = false;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "https://www.jobtransport.com"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.signUpSignIn.authority,
    };

    // this.login(editProfileFlowRequest);
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  dataSource: any = [];
  getClaims(claim?: any) {
    /*  this.dataSource = [
       { id: 1, claim: "Display Name", value: claims ? claims['name'] : null },
       { id: 2, claim: "Object ID", value: claims ? claims['oid'] : null },
       { id: 3, claim: "Job Title", value: claims ? claims['jobTitle'] : null },
       { id: 4, claim: "City", value: claims ? claims['city'] : null },
     ]; */
    if (claim) {
      return this.authService.instance.getActiveAccount().idTokenClaims[claim];
    }

    return this.authService.instance.getActiveAccount().idTokenClaims;
  }

  /*   ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    } */


}

