import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@env';
import { EventEmitter } from 'protractor';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';
// import { ProductDetailsComponent } from 'src/app/pages/products/product-details/product-details.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormControl } from '@angular/forms';

export type ErrorModel = [
  {
    code,
    message
  }
];

@Injectable({
  providedIn: 'root'
})

export class ErrorsService {
  errorMessage = new Subject<any>();
  env = environment;

  constructor(private snack: MatSnackBar) { }

  handleError(err: HttpErrorResponse) {
    let errorsList = {};

    for (const [index, item] of err.error.errors.entries()) {
      if (item.extensions === undefined) {
        this.errorMessage.next(`Une erreur s'est produite`);
        this.snack.open(`[${err.status} - ${err.statusText}] Une erreur s'est produite.`, 'OK', { verticalPosition: 'bottom', duration: 1500, panelClass: 'error' });
      } else {

        let fieldName = item.extensions.code.split(':')[0];
        const code = item.extensions.code.split(':')[1];

        // Username = Email
        fieldName === 'userName' ? fieldName = 'email' : fieldName = fieldName;

        if (code === undefined) {
          // No message var in DK
          if (this.env.GlobalVars.errors.msg[fieldName] === undefined) {
            this.errorMessage.next(`Une erreur s'est produite`);
            this.snack.open(`[${err.status} - ${err.statusText}] Une erreur s'est produite.`, 'OK', { verticalPosition: 'bottom', duration: 1500, panelClass: 'error' });
          } else {
            errorsList = { code: fieldName, message: this.env.GlobalVars.errors.msg[fieldName] };
          }
        } else {
          errorsList[fieldName] = { message: this.env.GlobalVars.errors.msg[code] };
        }

      }
    }

    this.errorMessage.next(errorsList);
    // return throwError(err);
  }

  handleAuthError(err: HttpErrorResponse) {

    if (err.status === 400) {
      this.errorMessage.next('une erreur est survenue.');
    }


    // return throwError(err);
  }
}

export class CustomError extends Validators {
  static customError(value) {

    if (value.value === null || value.value === '') {
      return { customError: true };
    }

  }
}