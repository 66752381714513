import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobtileComponent } from './jobtile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [JobtileComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [JobtileComponent]
})
export class JobtileModule { }
