import { Apollo } from 'apollo-angular';
import { HttpLinkHandler, HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { BrowserModule, BrowserTransferStateModule, makeStateKey, TransferState } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationModule } from './pages/authentication/authentication.module';

import { PopinComponent } from './components/popin/popin.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MyaccountComponent } from './pages/myaccount/myaccount.component';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SharedModule } from './shared.module';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { environment } from '@env';
import { HeaderModule } from './components/header/header.module';

import { NgxFaviconModule } from './lib/ngx-favicon/ngx-favicon.module';
import { customFavicons, AvailableCustomFavicons } from '../favicon.config';
import { FooterModule } from './components/footer/footer.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopinLoginComponent } from './components/popin/popin-login/popin-login.component';

import { HeaderService } from './core/services/header.service';
import { ScriptLoaderService } from './core/services/scriptloader.service';;
import { UserService } from './core/services/user.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FooterService } from './core/services/footer.service';
// import { AuthMSALService } from './core/services/auth-msal.service';
// import { MsalGuard, MsalGuardConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
// import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
//import { msalConfig } from './auth.config.ts';
import { AuthService } from './core/services/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CompanyPageService } from './core/services/companyPage.service';

registerLocaleData(localeFr, 'fr');

const STATE_KEY = makeStateKey('datas.state');

@NgModule({
  declarations: [
    AppComponent,
    PopinComponent,
    PopinLoginComponent,
    MyaccountComponent,
    NotfoundComponent,
  ],
  imports: [
    FlexLayoutModule,
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    //TransferHttpCacheModule,
    HttpClientModule,
    AuthenticationModule,
    SharedModule.forRoot(),
    HeaderModule,
    FooterModule,
    //ServiceWorkerModule.register('ngsw-worker.js', { enabled: (environment.production || environment.name === 'preprod'), registrationStrategy: 'registerImmediately' }),
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),
    MatTooltipModule,
    // MsalModule
  ],
  providers: [
    HeaderService,
    ScriptLoaderService,
    UserService,
    FooterService,
    AuthService,
    // ErrorsService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TransferStateInterceptor, multi: true }
    // AuthMSALService,
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: MSALInstanceFactory
    // },
    // {
    //   provide: MSAL_GUARD_CONFIG,
    //   useFactory: MSALGuardConfigFactory
    // },
    // MsalGuard,
    // MsalService,
    /*     {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        }, */
  ],
  // bootstrap: [AppComponent, MsalRedirectComponent]
  bootstrap: [AppComponent]
})
export class AppModule {
  cache: InMemoryCache;
  link: HttpLinkHandler;

  constructor(private readonly apollo: Apollo, private readonly transferState: TransferState,
    private readonly httpLink: HttpLink, @Inject(PLATFORM_ID) readonly platformId: Object) {
    const isBrowser = this.transferState.hasKey<any>(STATE_KEY);

    this.cache = new InMemoryCache();
    this.link = this.httpLink.create({ uri: environment.path.api });

    apollo.create({
      link: this.link,
      cache: this.cache,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      },
      // queries with `forceFetch` enabled will be delayed
      // ssrForceFetchDelay: 200,
      ssrMode: true
    });

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer() {
    // serializes the cache and puts it under a key
    this.transferState.onSerialize(STATE_KEY, () => {
      return this.cache.extract();
    });
  }

  onBrowser() {
    // reads the serialized cache
    const state = this.transferState.get<any>(
      STATE_KEY,
      null,
    );

    // and puts it in the Apollo
    this.cache.restore(state);
  }
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication(msalConfig);
// }

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
// export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//   return {
//     interactionType: InteractionType.Redirect,
//   };
// }

