import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { SidenavAccountComponent } from '../sidenav/sidenav-account/sidenav-account.component';
import { HeaderService } from 'src/app/core/services/header.service';
import { MaterialModule } from 'src/app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NavCandidateComponent } from './nav-candidate/nav-candidate.component';
import { NavRightComponent } from './nav-right/nav-right.component';
import { MatBadgeModule } from '@angular/material/badge';



@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    SidenavAccountComponent,
    NavCandidateComponent,
    NavRightComponent,
  ],
  imports: [
    MatBadgeModule,
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    MatBadgeModule
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    SidenavAccountComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class HeaderModule { }
