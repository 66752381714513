<mat-nav-list fxLayout>
  <ng-container *ngIf="!isLogged; else isLoggedNav">
    <!-- Candidates - Link to Espace recruteurs -->
    <mat-list-item class="header-nav__link header-nav__link--rt" [disableRipple]="true">
      <a href="espace-recruteurs/" disableRipple="true" title="Aller à l'Espace Recruteurs">
        Espace Recruteurs
      </a>
    </mat-list-item>

    <!-- Candidates - Link to Espace recruteurs -->
    <mat-list-item class="header-nav__link " [disableRipple]="true">
      <a href="candidats/identification-candidat.aspx" title="se connecter" disableRipple="true">
        Se connecter
      </a>
    </mat-list-item>

  </ng-container>

  <ng-template #isLoggedNav>

    <!--    <mat-list-item class="header-nav__link header-nav__link--login" #loggedBtn mat-button #navLogged="matMenuTrigger"
      (mouseenter)="headerService.buttonEnter(navLogged, render, el)"
      (mouseleave)="headerService.buttonLeave(navLogged, loggedBtn, render)" [matMenuTriggerFor]="accountMenu"
      style="z-index: 1050" hasBackdrop="false"> -->
    <mat-list-item class="header-nav__link header-nav__link--login" #loggedBtn mat-button #navLogged="matMenuTrigger"
      [matMenuTriggerFor]="accountMenu" style="z-index: 1050" hasBackdrop="false">
      <!-- <a class="header-nav__link-account" disableRipple="true" role="button" title="Mon compte">
        {{authService.datas?.shortname }}
      </a> -->

      <!--span class="header-nav__link-account-name">{{ name }}</span-->
      <!-- <span class="header-nav__link-account-name"> {{authService.datas?.company }}</span> -->

      <i style="margin-left: 15px" aria-hidden="true"
        class="header-nav__link-account-icon icon-css--arrow down small"></i>
    </mat-list-item>



    <!-- (Logged) Account submenu -->
    <mat-menu class="mat-elevation-z1 menu-border header-nav__menu" #accountMenu="matMenu" xPosition="before"
      [hasBackdrop]="true">
      <!--      <ul style="padding: 0" (mouseenter)="headerService.menuenter()"
        (mouseleave)="headerService.menuLeave(navLogged, loggedBtn, render)"> -->
      <ul style="padding: 0">
        <!-- CANDIDATES LOGGED -->
        <ng-container>
          <li>
            <a fxFlex=" 280px" [disableRipple]="true" fxLayout mat-menu-item [routerLink]="['profile']"
              title="Mes recommandations">
              <div fxFlex>Mes recommandations</div>
            </a>
          </li>
          <li>
            <a fxFlex="280px" [disableRipple]="true" fxLayout mat-menu-item [routerLink]="['profile']"
              title="Mes candidatures">
              <div fxFlex>Mes candidatures</div>
            </a>
          </li>

          <li>
            <a fxFlex="280px" [disableRipple]="true" fxLayout mat-menu-item [routerLink]="['profile']"
              title="Mes formations">
              <div fxFlex>Mes formations</div>
            </a>
          </li>
          <li>
            <a fxFlex="280px" [disableRipple]="true" fxLayout mat-menu-item [routerLink]="['profile']"
              title="Mes alertes">
              <div fxFlex>Mes alertes</div>
            </a>
          </li>

          <li>
            <a fxFlex="280px" [disableRipple]="true" mat-menu-item [routerLink]="['myaccount']" title="Mon compte">Mon
              compte</a>
          </li>
        </ng-container>

        <li class="menu-item-container-logout">
          <button fxFlex="280px" [disableRipple]="true" role="button" mat-menu-item (click)="logout()"
            title="se déconnecter">
            <mat-icon class="icon--round icon--round-bg">
              power_settings_new</mat-icon>
            ME DECONNECTER
          </button>
        </li>
      </ul>
    </mat-menu>

  </ng-template>


  <!--mat-list-item class="header-nav__link last" [disableRipple]="true" *ngIf="!isLogged">
    <!--a (click)="switchedTheme()">{{isRecruiters ? 'Espace Candidat' : 'Espace Recruteurs'}}</a -->
  <!-- a [href]="isRecruiters ? '/' : '/espace-recruteurs'"
      [title]="isRecruiters ? 'Espace Candidat' : 'Espace Recruteurs'">{{isRecruiters ? 'Espace Candidat' : 'Espace Recruteurs'}}</!-- a>
  </mat-list-item -->
</mat-nav-list>