import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { DOCUMENT } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class ApiService {
  wpUrl;
  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient, private apollo: Apollo, httpLink: HttpLink) {

    switch (environment.name) {
      case 'dev':
        this.wpUrl = 'dev.jobology.fr'
        break;
      case 'preprod':
        this.wpUrl = 'preprod.jobology.fr'
        break;
      case 'prod':
        this.wpUrl = 'www.cache.jobology.fr'
        break;
    }
  }



  public siteLog(): Observable<any> {
    let postData = new FormData();
    postData.append('portalid', '-1');
    postData.append('referrer', this.document.referrer);
    postData.append('url', (<any>window).location.href);
    postData.append('tabid', '-1');

    return this.http.post<any>('/DesktopModules/CollectSiteLog.aspx', postData);
  }

  public getJobDetails = (id: number) => {
    return this.apollo.watchQuery({
      query: gql`query job($id: Int!){
        job(id: $id) {
          id,
          title,
          detail,
          availability,
          salary,
          updatedDate,
          reference,
          charter,
          companyDescription,
          createdDate,
          dateView,
          isAnonyme,
          isChartered,
          matchingDate,
          partenaireId,
          refreshDate,
          responseLink,
          responseType,
          salaryView,
          showSalary,
          skills,
          sourceId,
          statusId
        }
      }`, variables: { id: id }
    });
  }

  // Blog
  public getBlogPostsList(perPage = 9, page = 1, offset = null): Observable<any> {
    let fields = `&_fields[]=author&_fields[]=tags&_fields[]=featured_media&_fields[]=yoast_head_json&_fields[]=categories&_fields[]=title&_fields[]=excerpt&_fields[]=id&_fields[]=date&_fields[]=slug&per_page=${perPage}&page=${page}&tags=${environment.path.blog.tagId}`;

    if (offset !== null) {
      fields = `&_fields[]=tags&_fields[]=featured_media&_fields[]=yoast_head_json&_fields[]=categories&_fields[]=title&_fields[]=excerpt&_fields[]=id&_fields[]=date&_fields[]=slug&per_page=${perPage}&offset=${offset}&tags=${environment.path.blog.tagId}`;
    }

    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/posts?${fields}`, { observe: 'response' });
  }

  public getBlogPost(slugName): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/posts?slug=${slugName}&tags=${environment.path.blog.tagId}`);
  }

  public getBlogComments(postId): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/comments?post=${postId}`);
  }

  public getBlogImg(postId): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/media/${postId}`);
  }

  public getBlogCategories(): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/categories?per_page=100`);
  }

  public getBlogCategoriesByName(categoryName): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/categories?slug=${categoryName}`);
  }

  public getBlogPostsListByCat(categoryId, perPage = 9, page = 1): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&tags=${environment.path.blog.tagId}`, { observe: 'response' });
  }

  public getBlogTags(categoryId): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/tags?categories=${categoryId}`);
  }

  public getBlogAuthors(): Observable<any> {
    const timestamp = Date.now();

    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/users`);
  }

  public getCGU(brandName): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/pages/?slug=cgu-${brandName}`);
  }

  public getCGV(): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/pages/?slug=conditions-generales-de-vente`);
  }

  public getPrivacyPolicy(brandName): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/pages/?slug=confidentialite-${brandName}`);
  }

  public getContact(): Observable<any> {
    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/pages/?slug=contactez-nous`);
  }

  public getAboutUs(): Observable<any> {
    const brand = environment.GlobalVars.brand.toLowerCase();
    const timestamp = new Date().getTime()

    return this.http.get(`https://${this.wpUrl}/wp-json/wp/v2/pages/?slug=qui-sommes-nous-${brand}&${timestamp}`);
  }

  // Auto Complete Country - Localité
  public locationAutocomplete = (startWith, countryCode, rows = 10, locationTypesList?) => {
    let locationTypes = [];

    if (locationTypesList) {
      locationTypes = locationTypesList
    }

    return this.apollo.watchQuery<{ locationAutocomplete }>({
      query: gql`query locationAutocomplete($startWith: String!, $countryCode: String, $rows: Int, $locationTypes: [String]) {
          locationAutocomplete(startWith: $startWith, countryCode: $countryCode, rows: $rows, locationTypes: $locationTypes) {
            countryCode
            flagUrl
            id
            latitude
            longitude
            name,
            displayName,
            fullDisplayName
          }
        }`, variables: { startWith: startWith, countryCode: countryCode, rows: rows, locationTypes: locationTypes }
    })
  }

  // Countries List
  public countries = (continentCode?) => {
    let cCode = continentCode ? continentCode : null;

    return this.apollo.watchQuery<{ countries }>({
      query: gql`query countries($continentCode: String) {
          countries(continentCode: $continentCode) {
            countryCode
            flagUrl
            latitude
            id
            longitude
            name
          }
        }`, variables: { continentCode: cCode }
    })
  }

}