import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  constructor() { }

  initTwitterWidget() {
    // this.twitter = this.router.events.subscribe(val => {
    // if (val instanceof NavigationEnd) {
    (<any>window).twttr = (function (d, s, id) {
      let js: any, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "twitter-wjs"));
  }

  initFBWidget() {
    (<any>window).fb = (function (d, s, id) {
      let js: any, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).fb || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v5.0";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "fb-wjs"));
  }

  initLinkedinWidget() {
    (<any>window).linkedin = (function (d, s, id) {
      let js: any, fjs = d.getElementsByTagName(s)[0],
        t = (<any>window).linkedin || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.linkedin.com/in.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "linkedin-wjs"));

    (<any>window).linkedin = (function (d, s, id) {
      let js1: any, fjs1 = d.getElementsByTagName(s)[0],
        t = (<any>window).linkedin || {};

      if (d.getElementById(id)) return t;
      js1 = d.createElement(s);
      js1.id = id;
      js1.setAttribute('data-url', "https:///www.linkedin.com/company/kuehne-nagel");
      js1.type = "IN/Share";
      d.getElementById('linkedin').append(js1, fjs1);

      t._e = [];
      t.ready = function (f: any) {
        t._e.push(f);
      };

      return t;
    }(document, "script", "linkedin-url"));
  }
}
