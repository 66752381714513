<!--div class="content__block">
  <h1 class="title">Se connecter</h1>

  <form ngClass="searchblock__form form" fxLayout="column" fxLayoutAlign="center center" [formGroup]="formLogin"
    novalidate (ngSubmit)="onSubmit()">
    <mat-form-field fxFlexAlign="stretch" class="searchblock__form-field">
      <input type="text" matInput class="input searchblock__form-input" formControlName="login" id="login"
        placeholder="Login">
      <mat-error>Identifiant incorrect</mat-error>
    </mat-form-field>

    <mat-form-field fxFlexAlign="stretch" class="searchblock__form-field">
      <input type="text" matInput class="input searchblock__form-input" formControlName="password" id="password"
        placeholder="Mot de passe">
      <mat-error>Mot de passe incorrect</mat-error>
    </mat-form-field>

    <button mat-button class="btn searchblock__form-btn" type="submit">
      Se connecter
    </button>
  </form>
</div -->
<loading *ngIf="loadingIsVisible" [static]="true"></loading>