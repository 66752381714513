import { Injectable, OnDestroy, Inject, NgZone } from '@angular/core';
import { OidcSecurityService, OidcSecurityStorage, OpenIdConfiguration, AuthWellKnownEndpoints, AuthorizationResult, AuthorizationState, OpenIdInternalConfiguration, OidcConfigService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { WindowRefService } from './window.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

  isAuthorized = false;
  userData: { name: string };
  roles = {
    recruiter: false,
    coordinator: false
  };

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private oidcSecurityStorage: OidcSecurityStorage,
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private originUrl: string,
    @Inject('AUTH_URL') private authUrl: string,
    // @Inject('windowObject') public window: Window
    public window: WindowRefService,
    private cookie: CookieService,
    private snack: MatSnackBar,
    private zone: NgZone
  ) {
  }

  private isAuthorizedSubscription: Subscription = new Subscription;

  ngOnDestroy(): void {

    if (this.isAuthorizedSubscription) {
      this.isAuthorizedSubscription.unsubscribe();
    }
  }


  public initAuth() {
    let logoutReturnUrlParam = this.window.nativeWindow.location.search.split('returnurl=')[1] ?
      this.window.nativeWindow.location.search.split('returnurl=')[1] : undefined;
    logoutReturnUrlParam = logoutReturnUrlParam ? logoutReturnUrlParam.replace('&set=', '?set=') : undefined;

    //logoutReturnUrlParam = logoutReturnUrlParam ? logoutReturnUrlParam.split('&set=')[0] : undefined;
    const logoutUrl = logoutReturnUrlParam ? this.window.nativeWindow.location.origin + decodeURIComponent(logoutReturnUrlParam) : this.originUrl;

    const openIdConfiguration: OpenIdConfiguration = {
      stsServer: this.authUrl,
      redirect_url: this.originUrl + '/mon-compte/callback',
      // redirect_url: '/authenticate/callback',
      client_id: 'angular',
      response_type: 'code',
      scope: 'openid profile roles recrutementapi',
      // post_logout_redirect_uri: (<any>sessionStorage).getItem('returnurlparam'),
      post_logout_redirect_uri: logoutUrl,
      // post_login_route: (<any>sessionStorage).getItem('returnurl'),
      forbidden_route: '/mon-compte/acces-refuse',
      unauthorized_route: '/',
      silent_renew: true,
      silent_renew_url: this.originUrl + '/silent-renew.html',
      history_cleanup_off: false,
      auto_userinfo: true,
      // log_console_warning_active: true,
      // log_console_debug_active: true,
      max_id_token_iat_offset_allowed_in_seconds: 20,
      iss_validation_off: true,
      // storage: localStorage
      trigger_authorization_result_event: true,
      start_checksession: true
    };

    const authWellKnownEndpoints: AuthWellKnownEndpoints = {
      issuer: this.authUrl,
      jwks_uri: this.authUrl + '/.well-known/openid-configuration/jwks',
      authorization_endpoint: this.authUrl + '/connect/authorize',
      token_endpoint: this.authUrl + '/connect/token',
      userinfo_endpoint: this.authUrl + '/connect/userinfo',
      end_session_endpoint: this.authUrl + '/connect/endsession',
      check_session_iframe: this.authUrl + '/connect/checksession',
      revocation_endpoint: this.authUrl + '/connect/revocation',
      introspection_endpoint: this.authUrl + '/connect/introspect',
    };

    this.oidcSecurityService.setupModule(openIdConfiguration, authWellKnownEndpoints);

    if (this.oidcSecurityService.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidcSecurityService.onModuleSetup.subscribe(() => {
        console.log('else module setup');
        this.doCallbackLogicIfRequired();
      });
    }

    this.zone.runOutsideAngular(() => {
      this.isAuthorizedSubscription = this.oidcSecurityService.getIsAuthorized().pipe(take(1)).subscribe(isAuthorized => {
        this.isAuthorized = isAuthorized;

        // Remove autoconnect key
        if (sessionStorage.getItem('set')) {
          //  (<any>window).location.href = sessionStorage.getItem('set');
          sessionStorage.removeItem('set');
        }
      });

      this.oidcSecurityService.onAuthorizationResult.subscribe(
        (authorizationResult: AuthorizationResult) => {
          this.onAuthorizationResultComplete(authorizationResult);
        });
    });

  }

  //public updateRoles: Subject<any> = new Subject<any>();
  public updateRoles: Subject<any> = new BehaviorSubject<any>(this.roles);
  handleRoles(userData) {
    userData.role.indexOf('RECRUTEUR') !== -1 ? this.roles.recruiter = true : this.roles.recruiter = false;
    userData.role.indexOf('RECRUTEUR-COORDINATEUR') !== -1 ? this.roles.coordinator = true : this.roles.coordinator = false;

    this.updateRoles.next(this.roles);
  }

  private onAuthorizationResultComplete(authorizationResult: AuthorizationResult) {
    /*console.log('Auth result received AuthorizationState:'
      + authorizationResult.authorizationState
      + ' validationResult:' + authorizationResult.validationResult);*/

    if (authorizationResult.authorizationState === AuthorizationState.unauthorized) {
      // alert('Une erreur est survenue');
      this.snack.open('Accès refusé.', 'OK');
      /* if (window.parent) {
        // sent from the child iframe, for example the silent renew
        this.router.navigate(['/unauthorized']);
      } else {
        window.location.href = '/unauthorized';
      } */
    }

    if (authorizationResult.authorizationState === AuthorizationState.authorized) {
      const returnUrl = sessionStorage.getItem('returnurl');
      const returnUrlParam = sessionStorage.getItem('returnurlparam');
      // this.oidcSecurityService.startCheckingSilentRenew();


      if (sessionStorage.getItem('returnurl') !== '/') {
        (<any>window).location.href = returnUrl;
      } else if (returnUrlParam && !returnUrlParam.includes('login')) {
        (<any>window).location.href = returnUrlParam;
        sessionStorage.removeItem('returnurlparam');

      } else {
        (<any>window).location.href = sessionStorage.getItem('returnurl');
      }

      // console.log(this.oidcSecurityService.getToken())
    }
  }

  private doCallbackLogicIfRequired() {
    this.oidcSecurityService.authorizedCallbackWithCode((<any>window).location.toString());
  }

  getIsAuthorized(): Observable<boolean> {
    return this.oidcSecurityService.getIsAuthorized();
  }

  getUserData() {
    return this.oidcSecurityService.getUserData<{ name: string, given_name: string, family_name: string, role: string[], sub: string }>();
  }

  updateUserData(nameInfos) {

    let userData = JSON.parse(this.cookie.get('userData_angular'));

    userData.given_name = nameInfos.given_name;
    userData.family_name = nameInfos.family_name;

    this.oidcSecurityStorage.write('userData', userData);
  }

  login(param) {
    if (param !== null) {

      this.oidcSecurityService.authorize((url) => {
        (<any>window).location.href = `${url}&${param}`;
      });
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  logout() {
    setTimeout(() => {
      this.oidcSecurityService.logoff();
    }, 200);
  }

  get(url: string): Observable<any> {
    return this.http.get(url, { headers: this.getHeaders() })
      .pipe(catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
  }

  put(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.put(url, body, { headers: this.getHeaders() })
      .pipe(catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.getHeaders() })
      .pipe(catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
  }

  post(url: string, data: any): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(url, body, { headers: this.getHeaders() })
      .pipe(catchError((error) => {
        this.oidcSecurityService.handleError(error);
        return throwError(error);
      }));
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.appendAuthHeader(headers);
  }

  public getToken() {
    const token = this.oidcSecurityService.getToken();

    return token;
  }

  private appendAuthHeader(headers: HttpHeaders) {
    const token = this.oidcSecurityService.getToken();

    if (token === '') { return headers; }

    const tokenValue = 'Bearer ' + token;
    return headers.set('Authorization', tokenValue);
  }
}

