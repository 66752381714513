import { environment } from '@env'

const brand = environment.GlobalVars.brand;
// enum all your different favicons
// (for type safety)
export enum CustomFavicon {
    FAVICON_JOBOLOGY_FR = 'faviconJOBOLOGY_FR',
    FAVICON_JT_FR = 'faviconJT_FR',
    FAVICON_DJ_FR = 'faviconDJ_FR',
    FAVICON_JV_FR = 'faviconJV_FR',
    FAVICON_CSEA_FR = 'faviconCSEA_FR',
    FAVICON_CT_FR = 'faviconCT_FR',
    FAVICON_CSPORT_FR = 'faviconCSPORT_FR',
    FAVICON_CPOWER_FR = 'faviconCPOWER_FR',
    FAVICON_CEARTH_FR = 'faviconCEARTH_FR',
    FAVICON_SC_FR = 'faviconSC_FR',
}

export type AvailableCustomFavicons = { [key in CustomFavicon]: string };

// -------------------------------------------------------------
// @warning do not forget to add your favicons to your bundle
// you should double check into angular.json file
// -------------------------------------------------------------
// map all the types of favicon to their href
export const customFavicons: AvailableCustomFavicons = {
    // for some reason, impossible to use the syntax
    // [CustomFavicon.FAVICON_SUCCESS]: 'favicon-success.ico',
    // otherwise we end up with an AOT ERROR
    faviconJOBOLOGY_FR: 'favicon-jobology.ico',
    faviconJT_FR: 'favicon-jt.ico',
    faviconDJ_FR: 'favicon-dj.ico',
    faviconJV_FR: 'favicon-jv.ico',
    faviconCSEA_FR: 'favicon-csea.ico',
    faviconCT_FR: 'favicon-ct.ico',
    faviconCSPORT_FR: 'favicon-csport.ico',
    faviconCPOWER_FR: 'favicon-cpower.ico',
    faviconCEARTH_FR: 'favicon-cearth.ico',
    faviconSC_FR: 'favicon-sc.ico',
};