import { NgModule, NO_ERRORS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from './material.module';
import { CommonModule, DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchblockComponent } from './components/searchblock/searchblock.component';
import { JobtileComponent } from './components/jobtile/jobtile.component';
import { IconsService } from './core/services/icons.service';
import { BreakpointService } from './core/services/breakpoints.service';
import { WindowRefService } from './core/services/window.service';
// import { ScriptLoaderService } from './core/services/scriptloader.service';
import { environment } from 'src/environments/environment';
import { RouterNavService } from './core/services/router-nav.service';
// import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { SocialService } from './core/services/social.service';
import { MetaService } from './core/services/meta.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobtileModule } from './components/jobtile/jobtile.module';

@NgModule({
  declarations: [
    SearchblockComponent,
    // NewsletterComponent,
    // ReinsuranceComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    JobtileModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LayoutModule,
    SearchblockComponent,
    FormsModule,
    ReactiveFormsModule,
    JobtileModule
    // NewsletterComponent,
    //ReinsuranceComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [IconsService, BreakpointService, WindowRefService,
        /*ScriptLoaderService,*/ RouterNavService, DatePipe, SocialService, MetaService,
        { provide: 'BASE_URL', deps: [], useValue: environment.path.root },
        { provide: 'AUTH_URL', useValue: environment.path.auth }]
    };
  }

}