import { Injectable } from '@angular/core';
import { BreakpointState, BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Observable, startWith } from 'rxjs';

@Injectable()
export class BreakpointService {
  isDesktop: Observable<BreakpointState>;
  // isMobile: Observable<BreakpointState>;
  isMobile;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isDesktop = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Web]);
    // this.isMobile = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait]);
    this.isMobile = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
    /*  this.isMobile = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
       map(result => result),
       startWith({ matches: true })
     ); */
  }
}