import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { AuthService } from 'src/app/core/services/auth.service';
import { AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { WindowRefService } from 'src/app/core/services/window.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomStorage } from 'src/app/core/services/custom-storage.service';
import { LogoutComponent } from './logout/logout.component';
import { CallbackComponent } from './callback/callback.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { Route, RouterModule } from '@angular/router';
import { ENODEV } from 'constants';
import { MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';

// import { LoadingComponent } from 'src/app/components/loading/loading.component';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


export function windowFactory() {
  return window;
}

/*let AuthRoutes: Route[] = [
  { path: 'identification', component: LoginComponent },
  { path: 'se-deconnecter', component: LogoutComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'acces-refuse', component: ForbiddenComponent },
  {
    path: '',
    loadChildren: () => import('src/app/pages/myaccount-recruiters/myaccount-recruiters.module').then(m => m.MyAccountRecruitersModule),
    // canActivate: [AuthGuard],
  }
] */

const AuthRoutes = /* environment.GlobalVars.brand === 'JOBOLOGY_FR' ? [
  { path: 'callback', loadChildren: () => import('src/app/pages/authentication/callback/callback.module').then(m => m.CallbackModule) },
  { path: 'identification', component: LoginComponent },
  { path: 'se-deconnecter', component: LogoutComponent },
  { path: 'acces-refuse', component: ForbiddenComponent },
  {
    path: '',
    loadChildren: () => import('src/app/pages/myaccount-recruiters/myaccount-recruiters.module').then(m => m.MyAccountRecruitersModule),
    // canActivate: [AuthGuard],
  }
]
  : */ [{ path: 'callback', loadChildren: () => import('src/app/pages/authentication/callback/callback.module').then(m => m.CallbackModule) },
  { path: 'identification', component: LoginComponent },
  { path: 'se-deconnecter', component: LogoutComponent },
  { path: 'acces-refuse', component: ForbiddenComponent },
  ];


@NgModule({
  declarations: [LoginComponent, LogoutComponent, ForbiddenComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    AuthModule.forRoot({ storage: CustomStorage }),
    LoadingModule.forRoot(),
    RouterModule.forChild(AuthRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    // AuthService,
    OidcSecurityService,
    CookieService,
    { provide: 'BASE_URL', deps: [], useValue: environment.path.root },
    { provide: 'AUTH_URL', useValue: environment.path.auth },
    { provide: 'API_URL', useValue: 'https://localhost:44340' },
  ]
})
export class AuthenticationModule { }
