import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nav-candidate',
  templateUrl: './nav-candidate.component.html',
  // encapsulation: ViewEncapsulation.None
  // styleUrls: ['../header.component.scss']
})
export class NavCandidateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
